import { IFlow } from '@homex/se-widget-flow-types';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  GET_UI_CONFIG_FAILURE,
  GET_UI_CONFIG_REQUEST,
  GET_UI_CONFIG_SUCCESS,
  fetchWithAuth,
  setCurrentCarouselItemToDefault,
} from 'actions';
import {
  CarouselReduxReducer,
  CountryCode,
  IState,
  IThemeFromUiConfig,
  IWidgetUiConfigResponse,
  Mode,
  ServiceType,
} from 'typings';
import { logNetworkError } from 'utils/logging';
import postMessageToParent from 'utils/postMessageToParent';
import { buildGetOptions } from 'utils/request';
import { timeout } from 'utils/timeout';

function getUiConfigFailure(error: unknown, url: string) {
  logNetworkError({
    error,
    url,
  });

  return { error, type: GET_UI_CONFIG_FAILURE };
}

function getUiConfigRequest() {
  return { type: GET_UI_CONFIG_REQUEST };
}

function getUiConfigSuccess(
  countryCodes: Array<CountryCode>,
  erpKey: string,
  flow: IFlow | null,
  theme: IThemeFromUiConfig,
  validOrigins: Array<string>,
  validServiceTypes: Array<ServiceType>,
) {
  return {
    countryCodes,
    erpKey: erpKey ?? undefined,
    flow: flow ?? undefined,
    theme,
    type: GET_UI_CONFIG_SUCCESS,
    validOrigins,
    validServiceTypes,
  };
}

export function getUiConfig(
  themeKey?: string,
  flowKey?: string,
  retryCount = 0,
) {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    const {
      auth: { sessionToken },
      client: { key: clientKey },
    } = getState();

    let url = `${process.env.REACT_APP_CONFIG_URI}?client_key=${clientKey}`;

    if (flowKey) {
      url += `&flow_key=${flowKey}`;
    }

    if (
      window.location.search.includes(process.env.REACT_APP_DEMO_SITE as string)
    ) {
      url += `&demo_site=true`;
    }

    if (themeKey) {
      url += `&theme_key=${themeKey}`;
    }

    dispatch(getUiConfigRequest());

    try {
      const options = buildGetOptions({ token: sessionToken });
      const response = await dispatch(fetchWithAuth(url, options, true));

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const {
        countryCode: countryCodes,
        // TODO(CUSTOM): Remove when done testing
        // flow = CUSTOMER_FIRST_FLOW,
        erpKey,
        flow,
        theme,
        validOrigins,
        validServiceTypes,
      }: IWidgetUiConfigResponse = await response.json();

      // Prevents us from needing to refresh the demo window when our app is rebuilt
      if (process.env.NODE_ENV === 'development') {
        const { mode } = getState().client;

        // to locally test ScheduleEngine.show({flowKey})
        if (mode === Mode.WEB && !flow?.key) {
          postMessageToParent(
            { analyticsKey: flow?.key, type: 'se-hide-modal' },
            validOrigins,
          );
        }
      }

      dispatch(
        getUiConfigSuccess(
          countryCodes,
          erpKey,
          flow,
          theme,
          validOrigins,
          validServiceTypes,
        ),
      );
      dispatch(setCurrentCarouselItemToDefault(CarouselReduxReducer.SERVICE));
    } catch (error) {
      if (retryCount <= 2) {
        const waitDuration = Math.min(2000 * retryCount, 120000);

        await timeout(waitDuration);
        await dispatch(getUiConfig(themeKey, flowKey, retryCount + 1));
      } else {
        dispatch(getUiConfigFailure(error, url));
      }
    }
  };
}
