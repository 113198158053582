/**
 * Action Type Constants
 */

export const ADD_ISSUE = 'ADD_ISSUE';
export const ADD_MEDIA_ID = 'ADD_MEDIA_ID';
export const ADD_UPLOADED_MEDIA_FILE_RECORD = 'ADD_UPLOADED_MEDIA_FILE_RECORD';
export const ADJUST_CAROUSEL_BOTTOM_HEIGHT = 'ADJUST_CAROUSEL_BOTTOM_HEIGHT';
export const ADJUST_CAROUSEL_TOP_HEIGHT = 'ADJUST_CAROUSEL_TOP_HEIGHT';
export const CHECKIN_DEVICE_FAILURE = 'CHECKIN_DEVICE_FAILURE';
export const CHECKIN_DEVICE_REQUEST = 'CHECKIN_DEVICE_REQUEST';
export const CHECKIN_DEVICE_SUCCESS = 'CHECKIN_DEVICE_SUCCESS';
export const CLEAR_ISSUE_STACK = 'CLEAR_ISSUE_STACK';
export const GET_UI_CONFIG_FAILURE = 'GET_UI_CONFIG_FAILURE';
export const GET_UI_CONFIG_REQUEST = 'GET_UI_CONFIG_REQUEST';
export const GET_UI_CONFIG_SUCCESS = 'GET_UI_CONFIG_SUCCESS';
export const HIDE_ACCOUNT_MODAL = 'HIDE_ACCOUNT_MODAL';
export const HIDE_APPOINTMENT_MODAL = 'HIDE_APPOINTMENT_MODAL';
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL';
export const HIDE_SERVICE_MODAL = 'HIDE_SERVICE_MODAL';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const PASSCODE_LOGIN_FAILURE = 'PASSCODE_LOGIN_FAILURE';
export const PASSCODE_LOGIN_REQUEST = 'PASSCODE_LOGIN_REQUEST';
export const REGISTER_DEVICE_FAILURE = 'REGISTER_DEVICE_FAILURE';
export const REGISTER_DEVICE_REQUEST = 'REGISTER_DEVICE_REQUEST';
export const REGISTER_DEVICE_SUCCESS = 'REGISTER_DEVICE_SUCCESS';
export const REGISTER_MEDIA_FAILURE = 'REGISTER_MEDIA_FAILURE';
export const REGISTER_MEDIA_REQUEST = 'REGISTER_MEDIA_REQUEST';
export const REGISTER_MEDIA_SUCCESS = 'REGISTER_MEDIA_SUCCESS';
export const REGISTER_PM_TRACKING_FAILURE = 'REGISTER_PM_TRACKING_FAILURE';
export const REGISTER_PM_TRACKING_REQUEST = 'REGISTER_PM_TRACKING_REQUEST';
export const REGISTER_PM_TRACKING_SUCCESS = 'REGISTER_PM_TRACKING_SUCCESS';
export const REMOVE_LAST_ISSUE = 'REMOVE_LAST_ISSUE';
export const REMOVE_MEDIA_ID = 'REMOVE_MEDIA_ID';
export const REMOVE_UPLOADED_MEDIA_FILE_RECORD =
  'REMOVE_UPLOADED_MEDIA_FILE_RECORD';
export const RESET_APP = 'RESET_APP';
export const RESET_SERVICE_CAROUSEL_FOR_CSR = 'RESET_SERVICE_CAROUSEL_FOR_CSR';
export const RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE =
  'RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE';
export const SET_ACCELERATED_ENTRY_POINT = 'SET_ACCELERATED_ENTRY_POINT';
export const SET_ACCOUNT_ORGANIZATION_ID = 'SET_ACCOUNT_ORGANIZATION_ID';
export const SET_ACCOUNT_PERSON_ID = 'SET_ACCOUNT_PERSON_ID';
export const SET_ACCOUNT_PROPERTY_ID = 'SET_ACCOUNT_PROPERTY_ID';
export const SET_AFFILIATE_SOURCE_TYPE = 'SET_AFFILIATE_SOURCE_TYPE';
export const SET_ANALYTICS_TRACKING_ENABLED = 'SET_ANALYTICS_TRACKING_ENABLED';
export const SET_APPOINTMENT_ID = 'SET_APPOINTMENT_ID';
export const SET_BLACKOUT_PERIOD = 'SET_BLACKOUT_PERIOD';
export const SET_BRAND_SOURCE_TYPE = 'SET_BRAND_SOURCE_TYPE';
export const SET_BROKER_SOURCE_TYPE = 'SET_BROKER_SOURCE_TYPE';
export const SET_CAMPAIGN_MEMBER_ID = 'SET_CAMPAIGN_MEMBER_ID';
export const SET_CAMPAIGN_NOTIFICATION_ID = 'SET_CAMPAIGN_NOTIFICATION_ID';
export const SET_CAMPAIGN_SOURCE_TYPE = 'SET_CAMPAIGN_SOURCE_TYPE';
export const SET_CAROUSEL_BOTTOM_HEIGHT = 'SET_CAROUSEL_BOTTOM_HEIGHT';
export const SET_CAROUSEL_HISTORY = 'SET_CAROUSEL_HISTORY';
export const SET_CAROUSEL_NATURAL_TOP_HEIGHT =
  'SET_CAROUSEL_NATURAL_TOP_HEIGHT';
export const SET_CAROUSEL_NAV_POINTER_X = 'SET_CAROUSEL_NAV_POINTER_X';
export const SET_CAROUSEL_TOP_HEIGHT = 'SET_CAROUSEL_TOP_HEIGHT';
export const SET_CLID_SOURCE_TYPE = 'SET_CLID_SOURCE_TYPE';
export const SET_CLIENT_KEY = 'SET_CLIENT_KEY';
export const SET_CLIENT_REFERRER = 'SET_CLIENT_REFERRER';
export const SET_CONTACT_INFO_CUSTOMER_LOCATIONS =
  'SET_CONTACT_INFO_CUSTOMER_LOCATIONS';
export const SET_CONTACT_INFO_PHONE_INPUT_VALUE =
  'SET_CONTACT_INFO_PHONE_INPUT_VALUE';
export const SET_CONTAINS_XSS_INPUT = 'SET_CONTAINS_XSS_INPUT';
export const SET_CURRENT_CAROUSEL_ITEM = 'SET_CURRENT_CAROUSEL_ITEM';
export const SET_CURRENT_CAROUSEL_ITEM_TO_PREVIOUS =
  'SET_CURRENT_CAROUSEL_ITEM_TO_PREVIOUS';
export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
export const SET_CUSTOM_FIELD_1 = 'SET_CUSTOM_FIELD_1';
export const SET_CUSTOM_FIELD_2 = 'SET_CUSTOM_FIELD_2';
export const SET_CUSTOM_FIELD_3 = 'SET_CUSTOM_FIELD_3';
export const SET_CUSTOM_FIELD_4 = 'SET_CUSTOM_FIELD_4';
export const SET_CUSTOM_FIELD_5 = 'SET_CUSTOM_FIELD_5';
export const SET_CUSTOM_FIELD_6 = 'SET_CUSTOM_FIELD_6';
export const SET_CUSTOM_FIELD_7 = 'SET_CUSTOM_FIELD_7';
export const SET_CUSTOM_FIELD_8 = 'SET_CUSTOM_FIELD_8';
export const SET_CUSTOM_FIELD_9 = 'SET_CUSTOM_FIELD_9';
export const SET_CUSTOM_FIELD_10 = 'SET_CUSTOM_FIELD_10';
export const SET_CUSTOM_FORM_DATA = 'SET_CUSTOM_FORM_DATA';
export const SET_CUSTOMER_LOCATION_PHONE = 'SET_CUSTOMER_LOCATION_PHONE';
export const SET_CUSTOMER_LOOKUP_CUSTOMER_LOCATIONS =
  'SET_CUSTOMER_LOOKUP_CUSTOMER_LOCATIONS';
export const SET_CUSTOMER_LOOKUP_PHONE_INPUT_VALUE =
  'SET_CUSTOMER_LOOKUP_PHONE_INPUT_VALUE';
export const SET_CUSTOMER_PHONE = 'SET_CUSTOMER_PHONE';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_FBCLID_SOURCE_TYPE = 'SET_FBCLID_SOURCE_TYPE';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_GCLID_SOURCE_TYPE = 'SET_GCLID_SOURCE_TYPE';
export const SET_GOOGLE_ANALYTICS_TRACKING_ENABLED =
  'SET_GOOGLE_ANALYTICS_TRACKING_ENABLED';
export const SET_HAS_INTERACTION = 'SET_HAS_INTERACTION';
export const SET_IS_AUTHORIZED_FOR_REPAIRS = 'SET_IS_AUTHORIZED_FOR_REPAIRS';
export const SET_IS_DISPATCH_FEE_ACCEPTED = 'SET_IS_DISPATCH_FEE_ACCEPTED';
export const SET_IS_EMERGENCY = 'SET_IS_EMERGENCY';
export const SET_IS_ISSUE_SPECIFIER_DROPDOWN_TOOLTIP_VISIBLE =
  'SET_IS_ISSUE_SPECIFIER_DROPDOWN_TOOLTIP_VISIBLE';
export const SET_IS_ISSUE_SPECIFIER_LIST_VIEW_TOOLTIP_VISIBLE =
  'SET_IS_ISSUE_SPECIFIER_LIST_VIEW_TOOLTIP_VISIBLE';
export const SET_IS_ISSUE_SPECIFIER_LIST_VIEW_VISIBLE =
  'SET_IS_ISSUE_SPECIFIER_LIST_VIEW_VISIBLE';
export const SET_IS_PREVIOUS_CUSTOMER = 'SET_IS_PREVIOUS_CUSTOMER';
export const SET_IS_STATIC = 'SET_IS_STATIC';
export const SET_ISSUE_OPTION_ANSWER = 'SET_ISSUE_OPTION_ANSWER';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_LEAD_ID = 'SET_LEAD_ID';
export const SET_LEAD_SOURCE_COMPANION_FIELD_VALUE =
  'SET_LEAD_SOURCE_COMPANION_FIELD_VALUE';
export const SET_LEAD_SOURCE_ID = 'SET_LEAD_SOURCE_ID';
export const SET_LEAD_SOURCE_SOURCE_TYPE = 'SET_LEAD_SOURCE_SOURCE_TYPE';
export const SET_LOGIN_PHONE = 'SET_LOGIN_PHONE';
export const SET_MEDIA_UPLOAD_SELECTION = 'SET_MEDIA_UPLOAD_SELECTION';
export const SET_MOBILE_NOTIFICATION_OPT_IN = 'SET_MOBILE_NOTIFICATION_OPT_IN';
export const SET_MODE = 'SET_MODE';
export const SET_NOTIFICATION_PREFERENCE = 'SET_NOTIFICATION_PREFERENCE';
export const SET_PLATFORM = 'SET_PLATFORM';
export const SET_PREFERRED_PARTNER = 'SET_PREFERRED_PARTNER';
export const SET_PREFERRED_SERVICE_TYPE = 'SET_PREFERRED_SERVICE_TYPE';
export const SET_REPAIR_COMMENTS = 'SET_REPAIR_COMMENTS';
export const SET_RWG_TOKEN_SOURCE_TYPE = 'SET_RWG_TOKEN_SOURCE_TYPE';
export const SET_SALES_ADDRESS = 'SET_SALES_ADDRESS';
export const SET_SCHEDULE_PREFERENCE_ENABLED =
  'SET_SCHEDULE_PREFERENCE_ENABLED';
export const SET_SE_ACTION_APPOINTMENT_ID = 'SET_SE_ACTION_APPOINTMENT_ID';
export const SET_SELECTED_CALENDAR_DATE = 'SET_SELECTED_CALENDAR_DATE';
export const SET_SELECTED_CALENDAR_MONTH = 'SET_SELECTED_CALENDAR_MONTH';
export const SET_SELECTED_ENERGY_SOURCES = 'SET_SELECTED_ENERGY_SOURCES';
export const SET_SELECTED_EQUIPMENT = 'SET_SELECTED_EQUIPMENT';
export const SET_SELECTED_ISSUE = 'SET_SELECTED_ISSUE';
export const SET_SELECTED_ROOM_ICON = 'SET_SELECTED_ROOM_ICON';
export const SET_SELECTED_ROOM_KEY = 'SET_SELECTED_ROOM_KEY';
export const SET_SELECTED_SCHEDULE_PREFERENCE =
  'SET_SELECTED_SCHEDULE_PREFERENCE';
export const SET_SELECTED_SCHEDULE_PREFERENCE_DAY =
  'SET_SELECTED_SCHEDULE_PREFERENCE_DAY';
export const SET_SELECTED_SCHEDULE_PREFERENCE_TIME =
  'SET_SELECTED_SCHEDULE_PREFERENCE_TIME';
export const SET_SELECTED_TIME_SLOT = 'SET_SELECTED_TIME_SLOT';
export const SET_SELECTED_UI_GROUPING = 'SET_SELECTED_UI_GROUPING';
export const SET_SERVICE_CODE_ID = 'SET_SERVICE_CODE_ID';
export const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';
export const SET_SERVICE_TYPE_RADIO_VALUE = 'SET_SERVICE_TYPE_RADIO_VALUE';
export const SET_SERVICE_ZONE_TIMEZONE = 'SET_SERVICE_ZONE_TIMEZONE';
export const SET_SHOULD_RECALCULATE_HEIGHT = 'SET_SHOULD_RECALCULATE_HEIGHT';
export const SET_SUBCONTRACTOR = 'SET_SUBCONTRACTOR';
export const SET_SYSTEM_COMMENTS = 'SET_SYSTEM_COMMENTS';
export const SET_THANK_YOU_REDIRECT_URL = 'SET_THANK_YOU_REDIRECT_URL';
export const SET_USER_INPUT = 'SET_USER_INPUT';
export const SET_UTM_CAMPAIGN_SOURCE_TYPE = 'SET_UTM_CAMPAIGN_SOURCE_TYPE';
export const SET_UTM_CONTENT_SOURCE_TYPE = 'SET_UTM_CONTENT_SOURCE_TYPE';
export const SET_UTM_MEDIUM_SOURCE_TYPE = 'SET_UTM_MEDIUM_SOURCE_TYPE';
export const SET_UTM_SOURCE_SOURCE_TYPE = 'SET_UTM_SOURCE_SOURCE_TYPE';
export const SET_UTM_TERM_SOURCE_TYPE = 'SET_UTM_TERM_SOURCE_TYPE';
export const SET_VIEWPORT_SIZE = 'SET_VIEWPORT_SIZE';
export const SHOW_ACCOUNT_MODAL = 'SHOW_ACCOUNT_MODAL';
export const SHOW_APPOINTMENT_MODAL = 'SHOW_APPOINTMENT_MODAL';
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const SHOW_SERVICE_MODAL = 'SHOW_SERVICE_MODAL';
export const START_SESSION_FAILURE = 'START_SESSION_FAILURE';
export const START_SESSION_REQUEST = 'START_SESSION_REQUEST';
export const START_SESSION_SUCCESS = 'START_SESSION_SUCCESS';
export const UPGRADE_SESSION_FAILURE = 'UPGRADE_SESSION_FAILURE';
export const UPGRADE_SESSION_REQUEST = 'UPGRADE_SESSION_REQUEST';
export const UPGRADE_SESSION_SUCCESS = 'UPGRADE_SESSION_SUCCESS';
export const VIEW_WIDGET_ONLY = 'VIEW_WIDGET_ONLY';
