import { Action } from 'redux';

import { Flow, IThemeFromUiConfig, ServiceType } from 'typings';

export enum CountryCode {
  AU = 'AU',
  CA = 'CA',
  US = 'US',
}

export enum Mode {
  APP = 'app',
  WEB = 'web',
}

export enum Platform {
  ANDROID = 'android',
  IOS = 'ios',
  WEB = 'web',
}

export interface IClientState {
  analyticsTrackingEnabled: boolean;
  countryCodes: Array<CountryCode>;
  erpKey?: string;
  flow?: Flow;
  googleAnalyticsTrackingEnabled: boolean;
  isStatic: boolean;
  key: string;
  mode: Mode;
  platform: Platform;
  preferredServiceType?: ServiceType;
  schedulePreferenceEnabled: boolean;
  theme?: IThemeFromUiConfig;
  validOrigins: Array<string>;
  validServiceTypes: Array<ServiceType>;
}

export interface IClientAction extends Action, Partial<IClientState> {}
