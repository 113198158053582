import {
  ADD_ISSUE,
  ADD_MEDIA_ID,
  ADD_UPLOADED_MEDIA_FILE_RECORD,
  CLEAR_ISSUE_STACK,
  REMOVE_LAST_ISSUE,
  REMOVE_MEDIA_ID,
  REMOVE_UPLOADED_MEDIA_FILE_RECORD,
  RESET_APP,
  RESET_SERVICE_CAROUSEL_FOR_CSR,
  RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE,
  SET_APPOINTMENT_ID,
  SET_BLACKOUT_PERIOD,
  SET_CONTACT_INFO_CUSTOMER_LOCATIONS,
  SET_CONTACT_INFO_PHONE_INPUT_VALUE,
  SET_CONTAINS_XSS_INPUT,
  SET_CURRENT_CUSTOMER,
  SET_CUSTOMER_LOCATION_PHONE,
  SET_CUSTOMER_LOOKUP_CUSTOMER_LOCATIONS,
  SET_CUSTOMER_LOOKUP_PHONE_INPUT_VALUE,
  SET_CUSTOMER_PHONE,
  SET_CUSTOM_FORM_DATA,
  SET_EMAIL,
  SET_FIRST_NAME,
  SET_ISSUE_OPTION_ANSWER,
  SET_IS_AUTHORIZED_FOR_REPAIRS,
  SET_IS_DISPATCH_FEE_ACCEPTED,
  SET_IS_EMERGENCY,
  SET_IS_ISSUE_SPECIFIER_DROPDOWN_TOOLTIP_VISIBLE,
  SET_IS_ISSUE_SPECIFIER_LIST_VIEW_TOOLTIP_VISIBLE,
  SET_IS_ISSUE_SPECIFIER_LIST_VIEW_VISIBLE,
  SET_IS_PREVIOUS_CUSTOMER,
  SET_LAST_NAME,
  SET_LEAD_ID,
  SET_LEAD_SOURCE_COMPANION_FIELD_VALUE,
  SET_LEAD_SOURCE_ID,
  SET_LOGIN_PHONE,
  SET_MEDIA_UPLOAD_SELECTION,
  SET_MOBILE_NOTIFICATION_OPT_IN,
  SET_NOTIFICATION_PREFERENCE,
  SET_PREFERRED_PARTNER,
  SET_REPAIR_COMMENTS,
  SET_SALES_ADDRESS,
  SET_SELECTED_ENERGY_SOURCES,
  SET_SELECTED_EQUIPMENT,
  SET_SELECTED_ISSUE,
  SET_SELECTED_ROOM_ICON,
  SET_SELECTED_ROOM_KEY,
  SET_SELECTED_SCHEDULE_PREFERENCE,
  SET_SELECTED_SCHEDULE_PREFERENCE_DAY,
  SET_SELECTED_SCHEDULE_PREFERENCE_TIME,
  SET_SELECTED_TIME_SLOT,
  SET_SELECTED_UI_GROUPING,
  SET_SERVICE_CODE_ID,
  SET_SERVICE_TYPE,
  SET_SERVICE_TYPE_RADIO_VALUE,
  SET_SERVICE_ZONE_TIMEZONE,
  SET_SE_ACTION_APPOINTMENT_ID,
  SET_SYSTEM_COMMENTS,
  SET_USER_INPUT,
} from 'actions';
import {
  IIssueStackItem,
  IUserInputAction,
  IUserInputState,
  MediaIdsTuple,
  NotificationPreference,
  UploadedMediaFileRecords,
} from 'typings';
import isIssueDetailsItemSkipped from 'utils/isIssueDetailsItemSkipped';
import isIssueImmediatelyRedirected from 'utils/isIssueImmediatelyRedirected';

const defaultState: IUserInputState = {
  address: {},
  contactInfoAllowLocationsNotInServiceZone: false,
  contactInfoCustomerLocations: [],
  contactInfoPhoneInputValue: '',
  containsXssInput: false,
  customForms: {},
  customerLocationPhone: {},
  customerLookupAllowLocationsNotInServiceZone: false,
  customerLookupCustomerLocations: [],
  customerLookupPhoneInputValue: '',
  customerPhone: {},
  isAuthorizedForRepairs: false,
  isDispatchFeeAccepted: false,
  isEmergency: false,
  isIssueSpecifierDropdownTooltipVisible: true,
  isIssueSpecifierListViewTooltipVisible: true,
  isIssueSpecifierListViewVisible: false,
  issueStack: [],
  loginPhone: {},
  mediaIds: [null, null, null, null],
  mobileNotificationOptIn: false,
  notificationPreference: [NotificationPreference.SMS],
  selectedEnergySources: [],
  selectedEquipment: [],
  uploadedMediaFileRecords: [null, null, null, null],
};

export default function userInput(
  state: IUserInputState = defaultState,
  action: IUserInputAction,
): IUserInputState {
  switch (action.type) {
    case ADD_ISSUE:
      if (!action.issue) {
        return state;
      }

      return {
        ...state,
        issueStack: [
          ...state.issueStack,
          {
            issue: action.issue,
            optionAnswers: [],
          },
        ],
      };

    case ADD_MEDIA_ID: {
      if (!action.mediaId || typeof action.mediaIdIndex === 'undefined') {
        return state;
      }

      const nextMediaIds = [...state.mediaIds] as MediaIdsTuple;
      nextMediaIds[action.mediaIdIndex] = action.mediaId;

      return {
        ...state,
        mediaIds: nextMediaIds,
      };
    }

    case ADD_UPLOADED_MEDIA_FILE_RECORD: {
      if (
        !action.mediaFileRecord ||
        typeof action.mediaFileRecordIndex === 'undefined'
      ) {
        return state;
      }

      const nextUploadedMediaFileRecords = [
        ...state.uploadedMediaFileRecords,
      ] as UploadedMediaFileRecords;

      nextUploadedMediaFileRecords[action.mediaFileRecordIndex] =
        action.mediaFileRecord;

      return {
        ...state,
        uploadedMediaFileRecords: nextUploadedMediaFileRecords,
      };
    }

    case SET_BLACKOUT_PERIOD:
      return {
        ...state,
        blackoutPeriod: action.blackoutPeriod,
      };
    case CLEAR_ISSUE_STACK:
      return {
        ...state,
        issueStack: [],
      };

    case REMOVE_LAST_ISSUE: {
      let nextIssueStack: Array<IIssueStackItem> = [...state.issueStack];
      let lastIssue;

      // Remove all issues from the end, until we find an issue that should be rendered as a
      // CarouselItemIssueDetails component
      do {
        nextIssueStack = nextIssueStack.slice(0, -1);

        const nextIssueStackItem = nextIssueStack[nextIssueStack.length - 1];

        lastIssue = nextIssueStackItem && nextIssueStackItem.issue;
      } while (
        lastIssue &&
        (isIssueDetailsItemSkipped(lastIssue) ||
          isIssueImmediatelyRedirected(lastIssue))
      );

      return {
        ...state,
        issueStack: nextIssueStack,
      };
    }

    case REMOVE_MEDIA_ID: {
      const nextMediaIds = state.mediaIds.map((mediaId) =>
        mediaId === action.mediaId ? null : mediaId,
      ) as MediaIdsTuple;

      return {
        ...state,
        mediaIds: nextMediaIds,
      };
    }

    case REMOVE_UPLOADED_MEDIA_FILE_RECORD: {
      if (typeof action.mediaFileRecordIndex === 'undefined') {
        return state;
      }

      const nextUploadedMediaFileRecords = [
        ...state.uploadedMediaFileRecords,
      ] as UploadedMediaFileRecords;

      nextUploadedMediaFileRecords[action.mediaFileRecordIndex] = null;

      return {
        ...state,
        uploadedMediaFileRecords: nextUploadedMediaFileRecords,
      };
    }

    case RESET_APP:
    case RESET_SERVICE_CAROUSEL_FOR_CSR:
    case RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE:
      return {
        ...defaultState,
        currentCustomer: state.currentCustomer,
      };
    case SET_APPOINTMENT_ID:
      return {
        ...state,
        appointmentId: action.appointmentId,
      };
    case SET_LEAD_ID:
      return {
        ...state,
        leadId: action.leadId,
      };
    case SET_SERVICE_TYPE:
      return {
        ...state,
        serviceType: action.serviceType,
      };
    case SET_SERVICE_TYPE_RADIO_VALUE:
      return {
        ...state,
        serviceTypeRadioValue: action.serviceTypeRadioValue,
      };

    case SET_NOTIFICATION_PREFERENCE: {
      const { notificationPreference = [] } = action;

      return {
        ...state,
        notificationPreference: [...notificationPreference],
      };
    }

    case SET_CONTACT_INFO_CUSTOMER_LOCATIONS: {
      const {
        contactInfoAllowLocationsNotInServiceZone = false,
        contactInfoCustomerLocations = [],
      } = action;

      return {
        ...state,
        contactInfoAllowLocationsNotInServiceZone,
        contactInfoCustomerLocations,
      };
    }

    case SET_CONTAINS_XSS_INPUT: {
      const { containsXssInput = false } = action;

      return {
        ...state,
        containsXssInput,
      };
    }

    case SET_CONTACT_INFO_PHONE_INPUT_VALUE: {
      const { contactInfoPhoneInputValue = '' } = action;

      return {
        ...state,
        contactInfoPhoneInputValue,
      };
    }

    case SET_CURRENT_CUSTOMER:
      return {
        ...state,
        currentCustomer: action.currentCustomer,
      };

    case SET_CUSTOM_FORM_DATA:
      if (!action.customForm) {
        return state;
      }

      return {
        ...state,
        customForms: {
          ...state.customForms,
          [action.customForm.carouselItemId]: action.customForm.data,
        },
      };

    case SET_CUSTOMER_LOOKUP_PHONE_INPUT_VALUE: {
      const { customerLookupPhoneInputValue = '' } = action;

      return {
        ...state,
        customerLookupPhoneInputValue,
      };
    }

    case SET_CUSTOMER_LOCATION_PHONE: {
      const { customerLocationPhone } = action;

      return {
        ...state,
        customerLocationPhone: customerLocationPhone
          ? {
              id: customerLocationPhone.id,
              isMobile: customerLocationPhone.isMobile,
              number: customerLocationPhone.number,
            }
          : {},
      };
    }

    case SET_CUSTOMER_LOOKUP_CUSTOMER_LOCATIONS: {
      const {
        customerLookupAllowLocationsNotInServiceZone = false,
        customerLookupCustomerLocations = [],
      } = action;

      return {
        ...state,
        customerLookupAllowLocationsNotInServiceZone,
        customerLookupCustomerLocations,
      };
    }

    case SET_CUSTOMER_PHONE: {
      const { customerPhone } = action;

      return {
        ...state,
        customerPhone: customerPhone
          ? {
              id: customerPhone.id,
              isMobile: customerPhone.isMobile,
              number: customerPhone.number,
            }
          : {},
      };
    }

    case SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.firstName,
      };
    case SET_IS_AUTHORIZED_FOR_REPAIRS:
      if (typeof action.isAuthorizedForRepairs === 'undefined') {
        return state;
      }

      return {
        ...state,
        isAuthorizedForRepairs: action.isAuthorizedForRepairs,
      };
    case SET_IS_DISPATCH_FEE_ACCEPTED:
      if (typeof action.isDispatchFeeAccepted === 'undefined') {
        return state;
      }

      return {
        ...state,
        isDispatchFeeAccepted: action.isDispatchFeeAccepted,
      };
    case SET_IS_EMERGENCY:
      if (typeof action.isEmergency === 'undefined') {
        return state;
      }

      return {
        ...state,
        isEmergency: action.isEmergency,
      };
    case SET_IS_ISSUE_SPECIFIER_DROPDOWN_TOOLTIP_VISIBLE:
      if (
        typeof action.isIssueSpecifierDropdownTooltipVisible === 'undefined'
      ) {
        return state;
      }

      return {
        ...state,
        isIssueSpecifierDropdownTooltipVisible:
          action.isIssueSpecifierDropdownTooltipVisible,
      };
    case SET_IS_ISSUE_SPECIFIER_LIST_VIEW_VISIBLE:
      if (typeof action.isIssueSpecifierListViewVisible === 'undefined') {
        return state;
      }

      return {
        ...state,
        isIssueSpecifierListViewVisible: action.isIssueSpecifierListViewVisible,
      };
    case SET_IS_ISSUE_SPECIFIER_LIST_VIEW_TOOLTIP_VISIBLE:
      if (
        typeof action.isIssueSpecifierListViewTooltipVisible === 'undefined'
      ) {
        return state;
      }

      return {
        ...state,
        isIssueSpecifierListViewTooltipVisible:
          action.isIssueSpecifierListViewTooltipVisible,
      };
    case SET_IS_PREVIOUS_CUSTOMER:
      return {
        ...state,
        isPreviousCustomer: action.isPreviousCustomer,
      };

    case SET_ISSUE_OPTION_ANSWER: {
      return {
        ...state,
        issueStack: state.issueStack.map((issueStackItem) => {
          const { issue, optionAnswers } = issueStackItem;

          if (issue.id !== action.issueId) {
            return issueStackItem;
          }

          if (typeof action.questionIndex === 'undefined') {
            return issueStackItem;
          }

          const nextOptionAnswers = [...optionAnswers];

          if (typeof action.optionAnswer !== 'undefined') {
            nextOptionAnswers[action.questionIndex] = action.optionAnswer;
          } else {
            nextOptionAnswers.splice(action.questionIndex, 1);
          }

          return {
            issue,
            optionAnswers: nextOptionAnswers,
          };
        }),
      };
    }

    case SET_LAST_NAME:
      return {
        ...state,
        lastName: action.lastName,
      };
    case SET_LEAD_SOURCE_COMPANION_FIELD_VALUE:
      return {
        ...state,
        leadSourceCompanionFieldValue: action.leadSourceCompanionFieldValue,
      };
    case SET_LEAD_SOURCE_ID:
      return {
        ...state,
        leadSourceId: action.leadSourceId,
      };

    case SET_LOGIN_PHONE: {
      const { loginPhone } = action;

      return {
        ...state,
        loginPhone: loginPhone
          ? {
              id: loginPhone.id,
              isMobile: loginPhone.isMobile,
              number: loginPhone.number,
            }
          : {},
      };
    }

    case SET_PREFERRED_PARTNER:
      return {
        ...state,
        preferredPartner: action.preferredPartner
          ? {
              ...action.preferredPartner,
            }
          : undefined,
      };
    case SET_SELECTED_ISSUE:
      return {
        ...state,
        selectedIssue: action.selectedIssue,
      };
    case SET_REPAIR_COMMENTS:
      return {
        ...state,
        repairComments: action.repairComments,
      };

    case SET_SELECTED_ENERGY_SOURCES: {
      const { selectedEnergySources = [] } = action;

      return {
        ...state,
        selectedEnergySources: [...selectedEnergySources],
      };
    }

    case SET_SELECTED_EQUIPMENT: {
      const { selectedEquipment = [] } = action;

      return {
        ...state,
        selectedEquipment: [...selectedEquipment],
      };
    }

    case SET_SELECTED_SCHEDULE_PREFERENCE:
      return {
        ...state,
        selectedSchedulePreference: action.selectedSchedulePreference,
      };
    case SET_SELECTED_SCHEDULE_PREFERENCE_DAY:
      return {
        ...state,
        selectedSchedulePreferenceDay: action.selectedSchedulePreferenceDay,
      };
    case SET_SELECTED_SCHEDULE_PREFERENCE_TIME:
      return {
        ...state,
        selectedSchedulePreferenceTime: action.selectedSchedulePreferenceTime,
      };
    case SET_SELECTED_TIME_SLOT:
      return {
        ...state,
        selectedTimeSlot: !action.selectedTimeSlot
          ? action.selectedTimeSlot
          : { ...action.selectedTimeSlot },
      };
    case SET_SELECTED_UI_GROUPING:
      return {
        ...state,
        selectedUiGrouping: action.selectedUiGrouping,
      };
    case SET_SELECTED_ROOM_KEY:
      return {
        ...state,
        selectedRoomKey: action.selectedRoomKey,
      };
    case SET_SELECTED_ROOM_ICON:
      return {
        ...state,
        selectedRoomIcon: action.selectedRoomIcon,
      };
    case SET_SALES_ADDRESS:
      return {
        ...state,
        address: {
          ...state.address,
          ...action.address,
        },
      };
    case SET_SERVICE_CODE_ID:
      return {
        ...state,
        serviceCodeId: action.serviceCodeId,
      };
    case SET_SERVICE_ZONE_TIMEZONE:
      return {
        ...state,
        serviceZoneTimezone: action.serviceZoneTimezone,
      };
    case SET_SE_ACTION_APPOINTMENT_ID:
      return {
        ...state,
        seActionAppointmentId: action.seActionAppointmentId,
      };
    case SET_SYSTEM_COMMENTS:
      return {
        ...state,
        systemComments: action.systemComments,
      };
    case SET_MEDIA_UPLOAD_SELECTION:
      return {
        ...state,
        mediaUploadSelection: action.mediaUploadSelection,
      };
    case SET_USER_INPUT:
      return {
        ...state,
        ...action.userInput,
      };
    case SET_MOBILE_NOTIFICATION_OPT_IN:
      return {
        ...state,
        mobileNotificationOptIn: action.mobileNotificationOptIn,
      };
    default:
      return state;
  }
}
