import { isUndefined, omitBy } from 'lodash';

import {
  IAccountTrackingState,
  IIssueStackItem,
  ISourceTrackingAnalyticsState,
  SchedulePreference,
  SchedulePreferenceTime,
} from 'typings';
import {
  IBookingOptionsInput,
  ISourceTrackingInput,
  MetadataNamespace,
  StartTimePreference,
} from 'typings/GraphQL';

interface IBuildBookingOptionsProps {
  accountTracking: IAccountTrackingState;
  analytics: ISourceTrackingAnalyticsState;
  customForms: Record<string, Record<string, unknown>>;
  enterpriseEnabled: boolean;
  enterpriseVersion: string;
  erpKey?: string;
  flowId?: string;
  isEmergency: boolean;
  issueStack?: Array<IIssueStackItem>;
  leadId?: string;
  mobileNotificationOptIn?: boolean;
  preferredPartnerId?: string;
  repairComments?: string;
  schedulePreferenceEnabled: boolean;
  selectedEnergySources: Array<string>;
  selectedEquipment: Array<string>;
  selectedSchedulePreference?: SchedulePreference;
  selectedSchedulePreferenceTime?: SchedulePreferenceTime;
  serviceCodeId?: string;
  strings: { [key: string]: string };
  systemComments?: string;
}

export const buildBookingOptions = ({
  accountTracking,
  analytics,
  customForms,
  enterpriseEnabled,
  enterpriseVersion,
  erpKey,
  flowId,
  isEmergency,
  issueStack = [],
  leadId,
  mobileNotificationOptIn,
  preferredPartnerId,
  repairComments,
  schedulePreferenceEnabled,
  selectedEnergySources,
  selectedEquipment,
  selectedSchedulePreference,
  selectedSchedulePreferenceTime,
  serviceCodeId,
  strings,
  systemComments,
}: IBuildBookingOptionsProps): IBookingOptionsInput => {
  const customFormDataArray = Object.keys(customForms).reduce(
    (arr, formKey) => {
      const form = customForms[formKey] ?? {};
      const formKeys = Object.keys(form);

      const displayFormTitles: Record<string, string> = formKeys.reduce(
        (acc, fk) => {
          //from snake_case to Snake Case
          const displayFormTitle = fk
            .split('_')
            .map((word) => word.charAt(0).toLocaleUpperCase() + word.slice(1))
            .join(' ');
          acc = {
            ...acc,
            [fk]: displayFormTitle,
          };

          return acc;
        },
        {},
      );

      formKeys.forEach((fk) => {
        arr.push(`${displayFormTitles[fk]}: ${form[fk]}`);
      });

      return arr;
    },
    [] as Array<string>,
  );

  const notes = issueStack
    .reduce(
      (noteList: Array<string>, issueStackItem) => [
        ...noteList,
        issueStackItem.issue.specifier.details.note || '',
        ...issueStackItem.optionAnswers.map(({ note }) => note || ''),
      ],
      [],
    )
    .filter((note) => !!note);

  customFormDataArray.forEach((cfData) => {
    notes.push(cfData);
  });

  const options: IBookingOptionsInput = {
    isEmergency,
    leadId,
    ...(mobileNotificationOptIn !== undefined && erpKey === 'servicetitan'
      ? {
          metadata: [
            {
              key: 'mobile_notification_opt_in',
              ns: MetadataNamespace.SERVICETITAN,
              value: mobileNotificationOptIn,
            },
          ],
        }
      : {}),
    notes,
    selectedEnergySources,
    selectedEquipment,
    serviceCodeId,
  };

  // build user comments
  if (repairComments || systemComments) {
    const userComments = [];

    if (repairComments) {
      userComments.push({
        name: strings['REPAIR_COMMENTS.SUBHEAD'] ?? '',
        value: repairComments,
      });
    }

    if (systemComments) {
      userComments.push({
        name: strings['SYSTEM_COMMENTS.SUBHEAD'] ?? '',
        value: systemComments,
      });
    }

    options.userComments = userComments;
  }

  if (schedulePreferenceEnabled) {
    switch (selectedSchedulePreference) {
      case SchedulePreference.FIRST_AVAILABLE:
        options.startTimePreference = StartTimePreference.FIRST_AVAILABLE;

        break;
      case SchedulePreference.PREFERRED_TIME:
        options.startTimePreference = selectedSchedulePreferenceTime;

        break;
      default:
        // Do nothing
        break;
    }
  }

  const params = new URL(window.location.href).searchParams;
  const clientUrl = params.get('client_url') || '';

  options.sourceTracking = {
    ...analytics,
    bookingSource: 'schedule_engine_widget',
    bookingSourceUrl: clientUrl,
    flowSource: flowId ?? '',
  };

  if (enterpriseEnabled && enterpriseVersion === '2') {
    options.sourceTracking.preferredPartner = preferredPartnerId;
  }

  options.sourceTracking = omitBy(options.sourceTracking, isUndefined);

  options.sourceTracking = Object.entries(options.sourceTracking).reduce(
    (newSourceTracking, sourceTrackingEntry): ISourceTrackingInput => {
      const [key, value] = sourceTrackingEntry;

      return { ...newSourceTracking, [key]: `${value}` };
    },
    {},
  );

  if (
    accountTracking &&
    accountTracking.personId &&
    accountTracking.organizationId
  ) {
    options.accountTracking = {
      organizationId: accountTracking.organizationId,
      personId: accountTracking.personId,
      propertyId: accountTracking.propertyId,
    };
  }

  return options;
};
