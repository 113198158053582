import {
  GET_UI_CONFIG_SUCCESS,
  RESET_APP,
  SET_ANALYTICS_TRACKING_ENABLED,
  SET_CLIENT_KEY,
  SET_GOOGLE_ANALYTICS_TRACKING_ENABLED,
  SET_IS_STATIC,
  SET_MODE,
  SET_PLATFORM,
  SET_PREFERRED_SERVICE_TYPE,
  SET_SCHEDULE_PREFERENCE_ENABLED,
} from 'actions';
import {
  CountryCode,
  IClientAction,
  IClientState,
  Mode,
  Platform,
  ServiceType,
} from 'typings';

const defaultState: IClientState = {
  analyticsTrackingEnabled: true,
  countryCodes: [CountryCode.US],
  googleAnalyticsTrackingEnabled: true,
  isStatic: false,
  key: '',
  mode: Mode.WEB,
  platform: Platform.WEB,
  schedulePreferenceEnabled: false,
  validOrigins: [],
  validServiceTypes: [
    ServiceType.SALES,
    ServiceType.REPAIR,
    ServiceType.MAINTENANCE,
  ],
};

export default function client(
  state: IClientState = defaultState,
  action: IClientAction,
): IClientState {
  switch (action.type) {
    case GET_UI_CONFIG_SUCCESS:
      if (
        !action.countryCodes ||
        !action.theme ||
        !action.validOrigins ||
        !action.validServiceTypes
      ) {
        return state;
      }

      return {
        ...state,
        countryCodes: action.countryCodes,
        erpKey: action.erpKey,
        flow: action.flow,
        theme: action.theme,
        validOrigins: action.validOrigins,
        validServiceTypes: action.validServiceTypes,
      };
    case SET_ANALYTICS_TRACKING_ENABLED:
      if (action.analyticsTrackingEnabled === undefined) {
        return state;
      }

      return {
        ...state,
        analyticsTrackingEnabled: action.analyticsTrackingEnabled,
      };
    case SET_CLIENT_KEY:
      if (!action.key) {
        return state;
      }

      return {
        ...state,
        key: action.key,
      };
    case SET_GOOGLE_ANALYTICS_TRACKING_ENABLED:
      if (action.googleAnalyticsTrackingEnabled === undefined) {
        return state;
      }

      return {
        ...state,
        googleAnalyticsTrackingEnabled: action.googleAnalyticsTrackingEnabled,
      };
    case SET_IS_STATIC:
      if (action.isStatic === undefined) {
        return state;
      }

      return {
        ...state,
        isStatic: action.isStatic,
      };
    case SET_MODE:
      if (!action.mode) {
        return state;
      }

      return {
        ...state,
        mode: action.mode,
      };
    case SET_PLATFORM:
      if (!action.platform) {
        return state;
      }

      return {
        ...state,
        platform: action.platform,
      };
    case SET_PREFERRED_SERVICE_TYPE:
      return {
        ...state,
        preferredServiceType: action.preferredServiceType,
      };
    case SET_SCHEDULE_PREFERENCE_ENABLED:
      return {
        ...state,
        schedulePreferenceEnabled: !!action.schedulePreferenceEnabled,
      };
    case RESET_APP:
      return defaultState;
    default:
      return state;
  }
}
